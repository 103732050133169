import React, { useRef, useEffect } from 'react';
import io from "socket.io-client";
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

function Mapa1(username) {
  let vista1 = useRef(null);
  const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
  useEffect(() => {
    const socket = io(socket_url, {
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      socket.emit('enlace_azul', username.username);
    });
    socket.on('datos', (data) => {
      const pc1 = document.getElementById("pc1");
      const pc2 = document.getElementById("pc2");
      const pc3 = document.getElementById("pc3");
      const pc4 = document.getElementById("pc4");

      if (pc2 && pc3 && pc4) {
        pc2.innerHTML = data.distancia1?.temp;
        let d1 = Math.round(Math.pow(10,((-59-(data.distancia1?.rssi))/(10*2.7)))*100)/100;
        pc3.innerHTML = d1
        pc4.innerHTML = data.distancia1?.time;
        pc1.innerHTML = data.distancia1?.humidty;

        if (isNaN(d1) && vista1.current) {
          vista1.current.style.display = 'none';
        } else if (vista1.current) {
          vista1.current.style.display = '';
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <table ref={vista1} border="border: 1px solid black">
        <thead>
          <tr>
            <th scope="col">Ubicación</th>
            <th scope="col">Temp (grados)</th>
            <th scope="col">Humedad (%)</th>
            <th scope="col">Fecha y hora</th>
            <th scope="col">Distancia</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Admisiones Principal: </td>
            <td id="pc2"></td>
            <td id="pc1"></td>
            <td id="pc4"></td>
            <td id="pc3"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Mapa1;
