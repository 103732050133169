import React, { useRef, useEffect } from 'react';
import './App.css';
import io from "socket.io-client";

function Mapa3(username) {
  let vista2 = useRef(null);
  useEffect(() => {
  const socket_url = process.env.REACT_APP_CONECT_SOCKET_PRO;
  const socket = io(socket_url,  {
    transports: ["websocket"]
  });
  socket.on('connect', () => {
    socket.emit('enlace_azul', username.username);
  });
  socket.on('datos', (data) => {
    const tc1 = document.getElementById("tc1");
    const tc2 = document.getElementById("tc2");
    const tc3 = document.getElementById("tc3");
    const tc4 = document.getElementById("tc4");

    if (tc2 && tc3 && tc4) {
    document.getElementById("tc2").innerHTML = data.distancia2?.temp;
    let d2 = Math.round(Math.pow(10,((-59-(data.distancia2?.rssi))/(10*2.7)))*100)/100;;
    document.getElementById("tc3").innerHTML = d2
    document.getElementById("tc4").innerHTML = data.distancia2?.time;
    document.getElementById("tc1").innerHTML = data.distancia2?.humidty;
    if (isNaN(d2) && vista2.current) {
      vista2.current.style.display = 'none';
    } else if (vista2.current) {
      vista2.current.style.display = '';
    }
    }
  });
}, []);
    return (
      <div>
    <table ref={vista2} border="1" >
                    <thead>
                      <tr>
                        <th scope="col">Ubicación</th>
                        <th scope="col">Temp (grados)</th>
                        <th scope="col">Humedad (%)</th>
                        <th scope="col">Fecha y hora</th>
                        <th scope="col">Distancia</th>
                      </tr>
                    </thead>
                    <tbody>
                                <tr>
                                    <td>Tomografía Torre 1: </td>
                                    <td id="tc2" data-sortable="true"></td>
                                    <td id="tc1" data-sortable="true"></td>
                                    <td id="tc4" data-sortable="true"></td>
                                    <td id="tc3" data-sortable="true"> </td>
                                  </tr>
                    </tbody>
                </table>
      </div>

    );
  }
  export default Mapa3;